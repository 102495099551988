import merge from "deepmerge";

import theme from "./theme";

export default merge(theme, {
  colors: {
    text: "#082c09",
    primary: "#082c09",
    secondary: "#426732",
  },
});
