/** @jsx jsx */
import "../fonts/stylesheet.css";

import React, { memo } from "react";
import { jsx, Styled, ThemeProvider } from "theme-ui";

import Homepage from "../components/Homepage";
import SEO from "../components/SEO";
import themeTerrestrial from "../theme-terrestrial";

const IndexTerrestrial: React.FC<Record<string, unknown>> = () => {
  return (
    <ThemeProvider theme={themeTerrestrial}>
      <Styled.root>
        <SEO title="Espèces terrestres" />
        <Homepage theme={"terrestrial"} />
      </Styled.root>
    </ThemeProvider>
  );
};

export default memo(IndexTerrestrial);
